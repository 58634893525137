import addCompaniesModule from '../../../modules/companies';
import addAccountModule from '../../../modules/account';
import addTripModule from '../../../modules/trip';
import addDocumentsConstructorModule from '../../../modules/documentsConstructor';
import addNewDocumentModule from '../../../modules/newDocument';
import addEditDocumentModule from '../../../modules/editDocument';

export default function () {
  const rise = window.Rise;

  addCompaniesModule(rise);
  addAccountModule(rise);
  addTripModule(rise);
  addDocumentsConstructorModule(rise);
  addNewDocumentModule(rise);
  addEditDocumentModule(rise);
}

import LoginTemplate from './html/login.html';
import UsersPage from './html/users.html';
import CompaniesPage from './html/companies.html';
import EmployeesPage from './html/employeesPage.html';
import EmployeePage from './html/employeePage.html';
import RailwayPage from './html/railway.html';
import AirlineCancelPage from './html/airlineCancel.html';
import FindTripPage from './html/findTrip.html';
import BookTripPage from './html/bookTrip.html';
import CompanyPage from './html/company.html';
import TripPage from './html/trip.html';
import SettingsPage from './html/settings.html';
import ReportPage from './html/report.html';
import AutoSendingEdo from './html/autoSendingEdo.html';
import PaymentCard from './html/paymentCard.html';
import MonetaryTransactions from './html/monetaryTransactions.html';
import GeneralActCompletedWorks from './html/generalActCompletedWorks.html';
import CartsAndNotesPage from './html/cartsAndNotes.html';
import CartNew from './html/cartNew.html';
import CartEdit from './html/cartEdit.html';
import CartToTrip from './html/cartToTrip.html';
import SearchUsersPage from './html/searchUsers.html';
import ReviseAct from './html/reviseAct.html';
import Unloading1c from './html/unloading1c.html';
import PaymentHotels from './html/paymentHotels.html';
import SendingDiadocPage from './html/sendingDiadoc.html';
import PackagesCorrectionPage from './html/packagesCorrection.html';
import AmoCrmPage from './html/amoCrm.html';
import SettingFunnels from './html/settingFunnels.html';
import CallsPage from './html/calls.html';
import HotelsPage from './html/hotelsPage.html';
import ReportTaxi from './html/reportTaxi.html';
import RegionsPage from './html/regionsPage.html';
import AutoSendingEmails from './html/autoSendinEmails.html';
import SearchTaxi from './html/searchTaxi.html';
import Calculator from './html/calculator.html';
import VipHallCancelPage from './html/vipHallCancel.html';
import AviaReport from './html/aviaReport.html';
import ClientSession from './html/clientSession.html';
import Mice from './html/mice.html';
import ConstructorPage from './html/constructor.html';
import ConstructorNew from './html/constructorNew.html';
import ConstructorEdit from './html/constructorEdit.html';
import Vacation from './html/vacation.html';
import VacationAccount from './html/vacationAccount.html';

const TEMPLATEID = {
  USERSPAGE: UsersPage,
  COMPANIESPAGE: CompaniesPage,
  EMPLOYEESPAGE: EmployeesPage,
  EMPLOYEEPAGE: EmployeePage,
  RAILWAYPAGE: RailwayPage,
  AIRLINECANCELPAGE: AirlineCancelPage,
  SEARCHTRIPSPAGE: FindTripPage,
  BOOKTRIPPAGE: BookTripPage,
  COMPANYPAGE: CompanyPage,
  TRIPPAGE: TripPage,
  LOGINPAGE: LoginTemplate,
  SETTINGSPAGE: SettingsPage,
  REPORTPAGE: ReportPage,
  AUTOSENDINGEDO: AutoSendingEdo,
  PAYMENT_CARD: PaymentCard,
  PPR_AND_KONTUR: MonetaryTransactions,
  GENERAL_ACT_COMPLETED_WORKS: GeneralActCompletedWorks,
  CARTSANDNOTESPAGE: CartsAndNotesPage,
  CART_NEW: CartNew,
  CART_EDIT: CartEdit,
  CART_TO_TRIP: CartToTrip,
  SEARCHUSERSPAGE: SearchUsersPage,
  REVISEACT: ReviseAct,
  UNLOADING1C: Unloading1c,
  PAYMENT_HOTELS: PaymentHotels,
  SENDINGDIADOCPAGE: SendingDiadocPage,
  PACKAGESCORRECTIONPAGE: PackagesCorrectionPage,
  AMOCRMPAGE: AmoCrmPage,
  SETTING_FUNNELS: SettingFunnels,
  CALLSPAGE: CallsPage,
  HOTELS_PAGE: HotelsPage,
  REPORT_TAXI: ReportTaxi,
  REGIONS_PAGE: RegionsPage,
  AUTO_SENDING_EMAILS: AutoSendingEmails,
  SEARCH_TAXI: SearchTaxi,
  CALCULATOR: Calculator,
  VIP_HALL_CANCEL: VipHallCancelPage,
  AVIA_REPORT: AviaReport,
  CLIENT_SESSION: ClientSession,
  MICE: Mice,
  CONSTRUCTORPAGE: ConstructorPage,
  CONSTRUCTOR_NEW: ConstructorNew,
  CONSTRUCTOR_EDIT: ConstructorEdit,
  VACATION: Vacation,
  VACATION_ACCOUNT: VacationAccount,
};

export default TEMPLATEID;

const CARTSANDNOTES = {
  INIT: 'init',
  SET: 'set',
  LOADING: 'loading',
  LOADCARTSANDNOTES: 'loadCartsAndNotes',
  CLEARCARTSANDNOTES: 'clearCartsAndNotes',
  SET_ANALYTICS: 'setAnalytics',
  SET_ITEM_CART: 'setItemCart',
  APPROVALS_LIST: 'approvalsList',
  SHOW_APPROVALS: 'showApprovals',
  LOADING_APPROVALS: 'loadingApprovals',
  CHANGE_APPROVERS: 'changeApprovers',
  SET_APPROVERS: 'setApprovers',
  COMMENT_FOR_APPROVE: 'commentForApprove',
  ERROR_APPROVE: 'errorApprove',
  CHANGE_CARTS: 'changeCarts',
  APPROVAL_NAME: 'approvalName',
  SAVE_INTERCOM_LINK: 'saveIntercomLink',
  CHANGE_INTERCOM_LINK: 'changeIntercomLink',
  ERROR_INTERCOM_LINK: 'errorIntercomLink',
};

export default CARTSANDNOTES;

const AIRLINE_CANCEL = {
  CANCEL_AIR_ITEM: 'cancelAirItem',
  REQUEST_ERROR: 'requestError',
  RESET: 'reset',
  UPLOAD_LOGO_FILE: 'uploadLogoFile',
  UPLOAD_VOUCHER_FILE: 'uploadVoucherFile',
  UPLOAD_LOGO_FILE_ERROR: 'uploadLogoFileError',
  UPLOAD_VOUCHER_FILE_ERROR: 'uploadVoucherFileError',
};

export default AIRLINE_CANCEL;

const BUSINESS = {
  UPDATETRIP: 'updateTrip',
  LOADING_PROVIDERS: 'loadingProviders',
  SHOW_SETTINGS: 'showAirlineProvider',
  UPDATE_AIRLINE_PROVIDERS: 'updateAirlineProvider',
  PRINT_DOC_STATUS: 'printDocStatus',
  PAYMENT_CARD: 'paymentCard',
  PPR_AND_KONTUR: 'monetaryTransactions',
  AUTO_SENDING_EDO: 'autoSendingEdo',
  GENERAL_ACT_COMPLETED_WORKS: 'generalActCompletedWorks',
  AVIA_REPORT: 'aviaReport',
};

export default BUSINESS;

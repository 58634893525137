const MENUITEMS = {
  USERS: 'users',
  NEWUSERS: 'new',
  AWAITINGUSERS: 'awaiting',
  CONFIRMEDUSERS: 'confirmed',
  REJECTEDUSERS: 'rejected',
  COMPANIES: 'companies',
  COMPANIESNEW: 'companiesNew',
  EMPLOYEES: 'employeesPage',
  RAILWAY: 'railway',
  AIRLINECANCEL: 'airlineCancel',
  SEARCHTRIPS: 'searchTrips',
  BOOKTRIP: 'bookTrip',
  SETTINGS: 'settings',
  AUTO_SENDING_EMAILS: 'autoSendingOfEmails',
  BUTTON: 'button',
  CLOSINGFILTER: 'closingFilter',
  DATEPICKER: 'datePicker',
  DIALOG: 'dialog',
  HISTORY: 'history',
  LOADING: 'loading',
  PAGINATE: 'paginate',
  PROGRESS: 'progress',
  PAYMENT_CARD: 'paymentCard',
  PPR_AND_KONTUR: 'monetaryTransactions',
  SELECT: 'select',
  TOOLTIP: 'toolTip',
  NOTIFICATION: 'notification',
  MAP: 'map',
  CAR: 'CAR',
  REPORT: 'report',
  REPORTTRIP: 'reportTrip',
  REPORT_ABOARD: 'reportAboard',
  REPORTDEBTOR: 'reportDebtor',
  REPORTCOMPANIESDAYSINDEBT: 'reportCompaniesDaysInDebt',
  REPORTACCOUNTS: 'reportAccounts',
  REINSURANCE: 'reInsurance',
  REPORTBOOKING: 'reportBooking',
  REPORTSTYLE: 'reportStyle',
  REPORTTAGS: 'reportTags',
  REPORTREPLY: 'reportReply',
  REPORTKPIPO: 'reportKpipo',
  REPORTKPINOTE: 'reportKpiNote',
  REPORTTIMECHAT: 'reportTimeChat',
  REPORTSTOPWORD: 'reportStopWord',
  REPORTCONSULTATIONS: 'reportConsultations',
  REPORTSENDINGTYPE: 'reportSendingTypes',
  REPORTEDITINGCLOSING: 'reportEditingClosing',
  REPORTPRINT: 'reportPrint',
  REPORTHOTELS: 'reportHotels',
  REPORTHOTELSPK: 'reportHotelsPk',
  REPORTAIRLINES: 'reportAirTickets',
  REPORTTRAINS: 'reportTrainTickets',
  REFUNDS_ONELIYA: 'reportRefundsOneliya',
  REPORTINCOMING: 'reportIncoming',
  REPORTSCHEMAS: 'reportSchemas',
  REPORT1: 'report1',
  SEARCHUSERS: 'searchUsers',
  REPORTPROCEEDS: 'reportProceeds',
  REVISEACTTEST: 'reviseActTest',
  REPORTMANAGERSACTIONS: 'reportManagersActions',
  AMOCRM: 'amocrm',
  UPLOADINGCONTACTS: 'uploadingContacts',
  AMOREPT: 'amorept',
  SETTING_FUNNELS: 'settingFunnels',
  BOOKKEEPING: 'bookKeeping',
  UNLOADING1C: 'unloading1c',
  PAYMENT_HOTELS: 'paymentHotels',
  SENDINGDIADOC: 'sendingDiadoc',
  PACKAGESCORRECTION: 'packagesCorrection',
  OVERDRAFT: 'overdraft',
  OVERDRAFT_SEC: 'overdraft',
  REPORTHOTELSSELLING: 'reportHotelsSelling',
  REPORTEMTYRATES: 'reportEmptyRates',
  REPORTEMTYAUTOCOMPLETES: 'reportEmptyAutocompletes',
  ANALYTICSAMO: 'analyticsAmo',
  GROUPINGHOTEL: 'groupingHotel',
  HOTEL_VAT: 'hotelVat',
  AA_VAT: 'aaVat',
  SERVICE: 'service',
  CALLS: 'calls',
  INSURANCE: 'insurance',
  INSURANCE_SECOND: 'insurance2',
  INSURANCE_THIRD: 'insurance3',
  SUPPLIERDOCUMENTS: 'supplierDocuments',
  TRIPBYDATE: 'tripByDate',
  CALL_APARTMENTS: 'CallApartments',
  PRINTDOCUMENTS: 'printDocuments',
  HOTELS: 'hotelsPage',
  FIND_HOTELS: 'searchHotels',
  ADD_HOTEL: 'addHotel',
  MATCH_HOTELS: 'matchHotels',
  ATC: 'atc',
  REPOST_TAXI: 'reportTaxi',
  REPORTS_PROVIDERS: 'reportProviders',
  AUTOSENDINGEDO: 'autoSendingEdo',
  GENERAL_ACT_COMPLETED_WORKS: 'generalActCompletedWorks',
  FIND_REGIONS: 'searchRegions',
  REGIONS: 'regionsPage',
  ADD_REGION: 'addRegion',
  CALCULATOR: 'calculator',
  TAXI: 'searchTaxi',
  VIP_HALL_CANCEL: 'vipHallCancel',
  CLIENT_SESSION: 'clientSession',
  MICE: 'mice',
  ALVIST: 'alvist',
  VACATION: 'vacation',
  DEV_PAGE: 'devPage',
};

export const V2_PAGES = [
  MENUITEMS.DEV_PAGE,
];

export default MENUITEMS;

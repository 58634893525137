const FILTER = {
  INIT: 'init',
  SETSEARCHFILTER: 'setSearchFilter',
  CHANGESEARCHFILTER: 'updateSearchFilter',
  CHANGESEARCHFILTERVALUE: 'changeSearchFilterValue',
  SETSEARCHFILTERBYQUERY: 'setSearchFilterByQuery',
  CANCELSEARCHFILTER: 'cancelSearchFilter',
  SETSEARCHTRAVELLERSFILTER: 'setSearchTravellersFilter',
  CANCELSEARCHTRAVELLERFILTER: 'cancelSearchTravellerFilter',
  CHANGESEARCHTRAVELLERFILTERVALUE: 'changeSearchTravellerFiltervalue',
  SEARCHORDERSFILTER: 'searchOrdersFilter',
  CHANGEISACTIVEBOOKTRIPS: 'changeIsActiveBookTrips',
  CHANGEISSEARCHBYINN: 'changeIsSearchByInn',
};

export default FILTER;

import './styles/index.scss';

import ROUTEOPTS from './routers';
import Template from './templates/html/app.html';
import BI from '../bi';

import addHeaderModule from '../modules/header';
import addMenuModule from '../modules/menu';
import addNotificationsModule from '../modules/notifications';

import beforeOnRouteChange from './hook/beforeOnRoute';
import beforeModuleInit from './hook/beforeModuleInit';

const APPOPTS = {
  appBox: '#app',
  pageBox: '#page',
  appTemplate: () => Template,
  beforeModuleInit,
  beforeOnRouteChange,
  router: ROUTEOPTS,
  user: {
    check: () => {
    },
    update: () => {
    },
  },
};

const initApp = (core) => BI(core);

const checkUser = function () {
  const userService = this.getService('Workplace');

  return userService.check().then((res) => ({
    isAuth: res.isAuth,
    url: '/login',
  }));
};

const initAppModule = (core) => {
  addHeaderModule(core);
  addMenuModule(core);
  addNotificationsModule(core);
};

export {
  APPOPTS, initApp, initAppModule, checkUser,
};

const HOTEL = {
  INPUT_SEARCH: 'INPUT_SEARCH_HOTEL',
  CHANGE_FIELD: 'CHANGE_FIELD_HOTELS',
  GET_HOTEL: 'GET_HOTEL',
  GET_TYPES_COHORT_AANDA: 'GET_TYPES_COHORT_AANDA',
  SEARCH_HOTELS: 'SEARCH_HOTELS',
  DO_REQUEST: 'DO_REQUEST',
  ADD_HOTEL: 'ADD_HOTEL',
  RESET: 'RESET_H',
  UPDATE_PLANS: 'UPDATE_PLANS',
  UPDATE_RATES: 'UPDATE_RATES',
  UPDATE_WORKS_WITH_VAT: 'UPDATE_WORKS_WITH_VAT',
  UPDATE_PRICE_INCL_VAT: 'UPDATE_PRICE_INCL_VAT',
  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  UPDATE_BAR: 'UPDATE_BAR',
  UPDATE_CONNECTION: 'UPDATE_CONNECTION',
  UPDATE_HIDE_RATES: 'UPDATE_HIDE_RATES',
  UPDATE_ACTIVATED_DATA: 'UPDATE_ACTIVATED_DATA',
  GET_RATE_TYPES: 'GET_RATE_TYPES',
  DELETE_PLAN: 'DELETE_PLAN',
  ADD_PLAN: 'ADD_PLAN',
  UPDATE_CONTRACT_HOTEL: 'UPDATE_CONTRACT_HOTEL',
  DELETE_CONTRACT_HOTEL: 'DELETE_CONTRACT_HOTEL',
  UPDATE_CONTRACT_HOTEL_FIELD: 'UPDATE_CONTRACT_HOTEL_FIELD',
  ADD_CONTRACT_HOTEL: 'ADD_CONTRACT_HOTEL',
  UPDATE_EC_LC_CONDITIONS_FIELD: 'UPDATE_EC_LC_CONDITIONS_FIELD',
  ADD_EC_LC_CONDITIONS: 'ADD_EC_LC_CONDITIONS',
  DELETE_EC_LC_CONDITIONS: 'DELETE_EC_LC_CONDITIONS',
  SEARCH_REGIONS: 'SEARCH_REGIONS',
  SET_PRELOADER: 'SET_PRELOADER',
  AUTOCOMPLITE_HOTEL: 'AUTOCOMPLITE_HOTEL',
  SET_AUTOCOMPLITE_MODAL: 'SET_AUTOCOMPLITE_MODAL',
  RESET_VALIDATION: 'RESET_VALIDATION',
  CHANGE_HAS_EARLY_CHECKIN: 'changeHasEarlyCheckout',
  CHANGE_HAS_LATE_CHECKOUT: 'changeHasLateCheckout',
  CHANGE_HAS_FREE_CANCELLATION: 'changeHasFreeCancellation',
  CHANGE_HAS_BREAKFAST: 'changeHasBreakfast',
  SET_CLIENT_EARLY_CHECKIN: 'changeClientEarlyCheckin',
  SET_CLIENT_LATE_CHECKOUT: 'changeClientLateCheckout',
  SET_CLIENT_BREAKFAST: 'changeClientBreakfast',
  SET_CLIENT_FREE_CANCELLATION: 'changeClientFreeCancellation',
  SET_CONTACTS: 'setContacts',
  SET_NO_CONTACTS: 'setNoContacts',
  SET_ADDITIONAL_EMAIL: 'setAdditionalEmail',
  SET_ADDITIONAL_COMMENT: 'setAdditionalComment',
  SET_TEMPLATE_ID: 'setTemplateId',
  SET_CUSTOMERS_LIST: 'setCustomersList',
  SET_SELECTED_CUSTOMERS: 'setSelectedCustomers',
  SET_NO_RATES: 'setNoRates',
  SET_ROOM_TYPE: 'setRoomType',
  SET_INTERCOM_ID: 'setIntercomChatId',
  CHANGE_FREE_CANCELLATION_DATE: 'changeFreeCancellationDate',
  CHANGE_CANCELLATION_DATE_WITH_FEE: 'changeCancellationDateWithFee',
  CHANGE_PRICES: 'changePrices',
  CHANGE_INTERCOM_LINK: 'changeIntercomLink',
  CHANGE_HOTEL_INPUT: 'changeHotelInput',
  CHANGE_VAT_AMOUNT: 'changeVatAmount',
  CHANGE_CHECKIN_DATE: 'changeCheckinDate',
  CHANGE_CHECKIN_TIME: 'changeCheckinTime',
  CHANGE_CHECKOUT_DATE: 'changeCheckoutDate',
  CHANGE_CHECKOUT_TIME: 'changeCheckoutTime',
  CHANGE_SELECTED_HOTEL: 'changeSelectedHotel',
  CHANGE_WORKS_WITH_VAT: 'changeWorksWithVat',
  CHANGE_PRICE_INC_VAT: 'changePriceIncVat',
  CHANGE_SELECTED_ROOMS: 'changeSelectedRooms',
  CHANGE_ADDITIONAL_EMAIL: 'changeAdditionalEmail',
  GET_HOTEL_INFO: 'getHotelInfo',
  GET_ROOMS_TYPES: 'getRoomsTypes',
  GET_SAVED_REQUESTS: 'getSavedRequests',
  RESET_BOOK_FORM: 'resetBookForm',
  UPDATE_LOADING: 'updateLoading',
  CHANGE_IS_EDITING: 'changeIsEditing',
  GET_EDITED_REQUEST: 'getEditedRequest',
  UPLOAD_CUSTOM_TEMPLATE_REQUEST: 'uploadCustomTemplateRequest',
  UPLOAD_TEMPLATE_FAILED: 'uploadTemplateFailed',
  RESET_CUSTOM_TEMPLATE_UPLOAD: 'resetCustomTemplateUpload',
  SET_IS_SELF_EMPLOYED: 'setIsSelfEmployed',
  CHANGE_REQUEST_STATUS: 'changeRequestStatus',
  SET_CUSTOMER_INFO: 'setCustomerInfo',
  SET_SELECTED_DEPARTMENT: 'setSelectedDepartmentId',
  SET_CUSTOMER_PROJECT_ID: 'setCustomerProjectId',
  GET_MANDATORY_PROJECT: 'getMandatoryProject',
  SET_EMPLOYEE_LIST: 'setEmployeeList',
  SET_HOTEL: 'setHotel',
  SET_TRIP_ID: 'setTripId',
  SET_ACCOUNT_ID: 'setAccountId',
  SET_CUSTOMER_PROJECTS: 'setCustomerProjects',
  SET_LETTER_SUBJECT: 'setLetterSubject',
  SET_COMMISSION: 'setSmartwayCommission',
  SET_HOTEL_ID: 'setHotelId',
  CHANGE_MATCH_ID: 'changeMatchId',
  ERROR_MATCH: 'errorMatch',
  SET_USERS: 'setUsers',
  SET_REQUEST_USER_ID: 'setRequestUserId',
  SET_CREATE_TRIP_FAILURE: 'setCreateTripFailure',
  SET_CREATE_TRIP_SUCCESS: 'setCreateTripSuccess',
  SET_NO_HOTEL_FOUND: 'setNoHotelFound',
  SET_CART_ID: 'setCartId',
  GET_ACCOUNT_ANALYTICS: 'getAccountAnalytics',
  SET_ACCOUNT_ANALYTICS: 'setAccountAnalytics',
  MATCHED_HOTELS: 'matchedHotels',
  LOADING_MATCHED_HOTELS: 'loadingMatchedHotels',
  RESET_MATCHES: 'resetMatches',
  IS_CHANGE_MATCHES: 'isChangeMatches',
  SET_COMPARES_ALL_MATCHES: 'setComparesAllMatches',
  SHOW_COMPARES_DIALOG: 'showComparesDialog',
  SET_GIATA_MAPPING: 'setGiataMapping',
  LOADING_RESTORE_HOTEL: 'loadingRestoreHotel',
};

export default HOTEL;
